import React from 'react';
import { Box, Button, Flex, HStack, Icon, Link, Text } from '@chakra-ui/react';
import { CardSinglePanel } from '@components/common';
import type { LayoutProps } from './types';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';

const linkProps = {
  target: '_blank',
  textStyle: 'sm-normal',
  color: 'primary.500',
  fontWeight: 500,
};

export function ErrorLayout({ children }: LayoutProps): JSX.Element {
  const { push } = useRouter();

  const redirectToHome = () => {
    void push('/');
  };

  return (
    <Flex
      direction='column'
      align='stretch'
      maxW='3xl'
      w='full'
      mx='auto'
      h='full'
    >
      <Box h='45%' py={12}>
        <Box>
          {children}
          <Button
            leftIcon={<Icon as={ArrowLeftIcon} />}
            onClick={redirectToHome}
            mb={16}
          >
            Go back
          </Button>
        </Box>

        <CardSinglePanel
          title='Need help?'
          subtitle='If you have questions or need assistance, our extensive documentation is ready for you, and you can also join our community Discord channel to talk to us. We’re here to help!'
          subtitleStyles={{ mb: 6 }}
        >
          <HStack spacing={4}>
            <Link href='https://clerk.com/docs' {...linkProps}>
              Read documentation
            </Link>

            <Link href='https://discord.com/invite/b5rXHjAg7A' {...linkProps}>
              Join community Discord
            </Link>
          </HStack>
        </CardSinglePanel>
      </Box>
      <Flex
        bg='white'
        p={4}
        width='100%'
        borderTop='1px solid'
        borderColor='gray.100'
        position='fixed'
        bottom={0}
        left={0}
        right={0}
      >
        <Text textStyle='sm-normal' color='gray.500'>
          Clerk © {new Date().getFullYear()}. All rights reserved
        </Text>
      </Flex>
    </Flex>
  );
}
